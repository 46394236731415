/* Container für die Roadmap */
.roadmap-container {
  position: relative;
  height: 981px;
  max-width: 2000px;
  background-image: url("../images/backgrounds/thirdComponent.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Roadmap-Punkt */
.roadmap-point {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  animation: pulse 1.5s infinite;
  cursor: pointer;
  z-index: 1;
}

/* Pulsieren des Punktes */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Popup */
.roadmap-popup {
  position: fixed; /* Verhindert, dass das Popup innerhalb des pulsierenden Punktes bleibt */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 0.9rem;
  z-index: 2;
  pointer-events: none;
  animation: none; /* Keine Animation */
  transform: translate(-50%, -100%); /* Zentriert oberhalb des Punktes */
}

/* Überschrift */
.roadmap-heading {
  text-align: center; /* Zentriert die Überschrift horizontal */
  font-size: 2.5rem; /* Passt die Schriftgröße an */
  font-weight: bold; /* Macht den Text fett */
  color: orange; /* Färbt die Überschrift orange */
  padding-top: 20px;
}
