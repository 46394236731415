* {

  margin-top: 0;
  padding: 0;
  
}



.app-container {
  /* Your other styles for the app container */
  overflow-y: scroll;
  scrollbar-width: none;
  scrollbar-color: #888 #f5f5f5;
}