li{
    list-style: none;
}

a{
    text-decoration: none;
    color: #fff;
    font-size: 1rem;    
}

a:hover{
    color: orange;
}

header{
    position: relative;
}

.navbar{
    width: 100%;
    height: 60px;
    max-width: 2000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 2;
}

.navbar .logo a{
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 100px;    
}

.navbar .links {
    display: flex;
    gap: 2rem;
    margin-bottom: 0; 
}

.navbar .toggle_btn{
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
}

.action_btn{
    margin-right: 100px;
    background-color: orange;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
    transition: scale 0.2 ease;
}

.action_btn:hover{
    scale: 1.05;
    color:#fff;
}

.action_btn:active{
    scale: 0.95;
}

/*Dropdown Menu*/
.dropdown_menu{
    display: none;
    position: absolute;
    z-index: 2;
    right: 0.2rem;
    top: 60px;
    height: 0;
    width: 300px;
    background: rgb(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 10px;
    overflow: hidden;
    transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown_menu.open{
    height: 240px;
}

.dropdown_menu li{
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown_menu .action_btn{
    width: 100%;
    display: flex;
    justify-content: center;
}

/*Responsive Design*/
@media (max-width: 992px) {
    .navbar .links,
    .navbar .action_btn {
        display: none;
    }

    .action_btn {
        margin-right: 0;
    }
    .navbar .toggle_btn{
        display: block;
        margin-right: 20px;
    }

    .dropdown_menu{
        display: block;        
    }

    .navbar .logo a{
        margin-left: 20px;    
    }
}

