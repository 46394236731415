
.home-container {
    position: relative; /* relative positionieren */
    height: 981px;
    max-width: 2000px;
    background-image: url('../images/backgrounds/firstComponentFrameContent.png');
    background-size: auto 100%; /* ändern Sie "cover" zu "auto 100%" */
    background-position: center; /* bleibt gleich */
    background-repeat: no-repeat; /* Hintergrundbild nicht wiederholen */
  }
  
  .overlay {
    position: absolute; /* absolut positionieren */
    top: 0;
    left: 0;
    height: 981px;
    max-width: 2000px;
    width: 100%; 
    background-image: url('../images/backgrounds/firstComponentFrame.png');
    background-position: center; /* Bild in der Mitte ausrichten */
    background-repeat: no-repeat; /* Hintergrundbild nicht wiederholen */
    z-index: -1; /* Geben Sie der überlagernden Ebene eine höhere z-index als der unteren Ebene */
  }


.main-container{
  position: relative;
  height: 981px;
  max-width: 2000px;
}

.container{
  height: 981px;
  max-width: 2000px;
  width: 100%;
  position: absolute;
  background-image: url('../images/backgrounds/firstComponentFrame2.png');
  background-position: center top; /* Bild in der Mitte ausrichten */
  background-repeat: no-repeat; /* Hintergrundbild nicht wiederholen */
  background-size: cover;
  z-index: 1;
}

  .grid-container {
    display: grid;
    grid-template-columns: 1200px;
    grid-template-rows: 150px 550px 240px;
    grid-row-gap: 20px;
    grid-column-gap: 50px;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
    
 }


  .a {
    background: #e74c3c;
    border: 1px #2c3e50 solid;
    border-radius: 0.2rem;
    text-align: center;
    font-size: 3rem;

  }
  .c {
    background: #e74c3c;
    border: 1px #2c3e50 solid;
    border-radius: 0.2rem;
    text-align: center;
    font-size: 3rem;
   
  }
  
  .b {
    background: grey;
    overflow: hidden;
    text-align: center;
  }
  

  
  