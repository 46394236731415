/* Allgemeine Layout-Einstellungen */
.head-container {
  height: 1342px;
  max-width: 2000px;
  background-image: url("../images/backgrounds/secondComponent.png");
  background-size: cover;
  background-position: center;
}

.head-content {
  margin: auto;
}

.hgrid-container {
  display: grid;
  grid-template-columns: 700px;
  grid-template-rows: 350px 750px 240px;
  grid-row-gap: 20px;
  grid-column-gap: 50px;
  justify-content: center;
}

.ha {
}

.hb {
  /* border: 1px #2c3e50 solid; */
  overflow: hidden;
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.hc {
  /* Passt sich dem Star-Wars-Intro an */
}

/* Star Wars Intro CSS */
.star-wars-intro {
  background-color: transparent;
  width: 100%;
  height: 100%;
  font-family: "Droid Sans", arial, verdana, sans-serif;
  font-weight: 500;
  color: #ebd71c;
  overflow: hidden;
  position: relative;
}

.star-wars-intro p.intro-text {
  position: relative;
  max-width: 16em;
  font-size: 200%;
  font-weight: 400;
  margin: 20% auto;
  color: #ebd71c;
  opacity: 0;
  z-index: 1;
  text-align: center;
  animation: intro 2s ease-out;
}

.star-wars-intro .main-content {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  z-index: 3;
  width: 98%;
  height: 50em;
  bottom: 0;
  font-size: 40px;
  font-weight: bold;
  text-align: justify;
  overflow: hidden;
  transform-origin: 50% 100%;
  transform: perspective(350px) rotateX(25deg);
}

.star-wars-intro .main-content:after {
  position: absolute;
  content: " ";
  top: 0;
  bottom: 60%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    transparent 100%
  );
  pointer-events: none;
}

.star-wars-intro .space-button {
  color: #ebd71c;
  border: 12px solid #ebd71c;
  padding: 20px;
  background: transparent;
  text-decoration: none;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.star-wars-intro .space-button:hover {
  background-color: #d2be03;
  border-color: #d2be03;
  color: black;
}

.star-wars-intro .space-button:active,
.star-wars-intro .space-button:focus {
  background-color: #b8a40a;
  border-color: #b8a40a;
  color: black;
}

/* ... bestehende CSS-Regeln ... */

.star-wars-intro .title-content {
  position: absolute;
  top: 100%;
  animation: scroll 100s linear 1ms infinite; /* Endloser Loop */
}

.star-wars-intro .title-content > .content-header {
  text-align: center;
}

.star-wars-intro .main-logo {
  position: absolute;
  width: 2.6em;
  left: 50%;
  top: 20vh;
  font-size: 10em;
  text-align: center;
  margin-left: -1.3em;
  line-height: 0.8em;
  letter-spacing: -0.05em;
  color: #000;
  text-shadow: -2px -2px 0 #ebd71c, 2px -2px 0 #ebd71c, -2px 2px 0 #ebd71c,
    2px 2px 0 #ebd71c;
  opacity: 0;
  z-index: 1;
  animation: logo 5s ease-out 2.5s;
}

.star-wars-intro .main-logo > img {
  max-width: 100%;
}

/* Start-Button */
.start-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Immer noch mittig */
  padding: 10px 20px;
  font-size: 1.5rem;
  background-color: #ebd71c;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  animation: pulsate 2s ease-in-out infinite; /* Pulsierende Animation */
}

/* Pulsierende Button-Animation */
@keyframes pulsate {
  0% {
    transform: translate(-50%, -50%) scale(1); /* Mittig und normale Größe */
    opacity: 1; /* Volle Sichtbarkeit */
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1); /* Mittig und etwas größer */
    opacity: 0.8; /* Leichte Transparenz */
  }
  100% {
    transform: translate(-50%, -50%) scale(1); /* Zurück zur normalen Größe */
    opacity: 1; /* Volle Sichtbarkeit */
  }
}

/* Keyframes */
@keyframes intro {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes logo {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(0.1);
    opacity: 0;
  }
}

/* Keyframes */
@keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -170%;
  }
}

/* Responsive Design */
@media screen and (max-width: 720px) {
  .star-wars-intro .main-content {
    font-size: 35px;
  }
  .star-wars-intro .title-content {
    animation: scroll 100s linear 4s forwards;
  }
}
